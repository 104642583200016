import { DeDUBox, DeDUForm, DeDUGroup, DeDUImage, DeDUPage, DeDUStack, DeDUText, DeDUTitle } from '@dedu-internal/dedu-ui';
import { AxiosResponse } from 'axios';
import { ComponentProps, useEffect, useState } from 'react';
import * as Yup from "yup";

type Form<T extends object> = ComponentProps<typeof DeDUForm<T>>

const fooForm: Form<any> = {
  items: [
    {
      key: "fullName",
      label: "För- och efternamn",
      placeholder: "T. ex Gunnar Jönsson",
      schema: Yup.string()
        .min(2, "Name should have at least 2 letters")
        .required(),
      type: "text",
      value: "",
    },
    {
      key: "address",
      label: "Adress",
      options: ["Västra Kyrkogatan 1", "Storgatan 12A", "Sockenvägen 32"],
      placeholder: "Ange din adress",
      schema: Yup.string().required("You must assign your gender"),
      type: "select",
      value: "",
    },
    {
      key: "description",
      label: "Beskrivning",
      placeholder: "Beskriv felet så utförligt som möjligt",
      schema: Yup.string().min(
        2,
        "Description should have at least 10 letters"
      ).required(),
      type: "textarea",
      value: "",
    },
    {
      key: "date",
      label: "Datum",
      placeholder: "När upptäcktes felet?",
      schema: Yup.date()
        .required("Date is required")
        .max(new Date(), "Date must be in the past")
        .min(new Date(1900, 1, 1), "You can't be that old!"),
      type: "date",
      value: null,
    },
  ],
  gridProps:{
    columns: 2,
  },
  onClear: () => console.log("Cleared!"),
  onErrors: errors => console.log("Errors", errors),
  onReset: () => console.log("Resetted!"),
  submit: {
    clearAfterSubmit: true,
    onSubmit: () => Promise.resolve({} as AxiosResponse),
    resolveErrorMessage: err => `${err} happened!`,
    resolveSuccessMessage: response =>
      `Submit succeeded with status: ${response.statusText}`,
  },
  submitButton: { label: "Skicka" }
}

function App() {
  const [form, setForm] = useState<Form<any>>()
  const [loading, setLoading ] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setForm(fooForm)
      setLoading(false)
    }, 3000);

  }, [])


  return (
    <DeDUPage containerProps={{ m: "auto"}} isLoading={loading}>
      { loading ? null : (
        <>
        { !form ? (
          <DeDUStack sx={{textAlign: "center"}}><DeDUTitle>Hoppsan!</DeDUTitle><DeDUText sx={{whiteSpace: "pre-line"}}>{`Formulär kunde ej hämtas. Se till att länken är korrekt.\nOm problemet kvarstår kontakta DeDU support.`}</DeDUText></DeDUStack>
        ) : (
          <>
      <DeDUGroup position="apart" mb="md">
        <DeDUTitle order={4}>Felanmälan - (Kundnamn)</DeDUTitle>
        <DeDUImage src='/logo-placeholder.png' alt="(Kundnamn)" height={48} width={100} />
      </DeDUGroup>
        <DeDUForm<any> {...form} />
          </>
        )}
        </>
      )}
    </DeDUPage>
  );
}

export default App;
